export const getArrayWithRelocatedEntry = (array, originalEntryIndex, finalEntryIndex) => {
  const resultingArray = Array.from(array)

  const [entry] = resultingArray.splice(originalEntryIndex, 1)

  resultingArray.splice(finalEntryIndex, 0, entry)

  return resultingArray
}


/**
 * removes an entry from an objects array referenced by `id` property
 */
export const getArrayWithoutEntry = (array, itemIdToRemove) =>
  array.filter(entry => entry.id !== itemIdToRemove)


/**
 * replaces an objects array's entry referenced by `id` property
 */
export const getUpdatedArray = (array, updatedEntry) =>
  array.map(entry =>
    (entry.id === updatedEntry.id) ? updatedEntry : entry
  )


export const getSortedByKey = (array, key) =>
  array.sort((a, b) => {
    const propA = a[key].toUpperCase()
    const propB = b[key].toUpperCase()

    if (propA < propB) {
      return -1
    }
    if (propA > propB) {
      return 1
    }

    return 0
  })
