import { isRequired, isZeroOrPositiveInteger } from '../../helpers/Form'


export const courseFields = {
  status: {
    value: 'draft'
  },
  title: {
    validators: [isRequired]
  },
  content: {},
  specialties: {
    value: []
  },
  price: {
    validators: [isRequired, isZeroOrPositiveInteger]
  },
  teamSize: {
    validators: [isRequired]
  },
  level: {
    validators: [isRequired]
  },
  sections: {
    value: []
  },
  featuredMedia: {
    validators: [isRequired],
    value: null
  }
}


export const statusOptions = [
  { value: 'draft', label: 'Draft' },
  { value: 'public', label: 'Public' }
]


export const teamSizeOptions = [
  { value: 'DIY', label: 'DIY' },
  { value: 'Small Team', label: 'Small Team' },
  { value: 'Large Team', label: 'Large Team' }
]


export const levelOptions = [
  { value: 'Beginner', label: 'Beginner' },
  { value: 'Intermediate', label: 'Intermediate' },
  { value: 'Advanced', label: 'Advanced' }
]
