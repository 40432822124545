import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'

import { getArrayWithoutEntry, getUpdatedArray } from '../../../helpers'

import { CoursePart, InlineEdit } from '../../specialized-components'
import { icons, IconButton } from '../../ui-blocks'

import { DraggableTopics } from './DraggableTopics'
import { getSectionsBasedOnDragAndDropAction } from './helpers'


const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'transparent'
})


const getSectionClassName = (section, lastEditedLessonId) => {
  const classNames = []

  if (section.id === lastEditedLessonId) {
    classNames.push('border')
  }

  return classNames.join(' ')
}


export const CourseSchedule = ({ sections, setSections, lastEditedLessonId, setLastEditedLessonId }) => {

  const handleDragEnd = action => {
    if (!action.destination) {
      return
    }

    const destinationId = Number(action.destination.droppableId)

    const isLesson = sections.find(section => section.id === destinationId)

    if (isLesson) {
      setLastEditedLessonId(destinationId)
    }


    const updatedSections = getSectionsBasedOnDragAndDropAction(sections, action)
    setSections(updatedSections)
  }


  const setSectionTitle = (editedSection, newTitle) => {
    const updatedSection = {
      ...editedSection,
      title: newTitle
    }

    if (editedSection.children) {
      setLastEditedLessonId(editedSection.id)
    }

    setSections(getUpdatedArray(sections, updatedSection))
  }


  const handleRemoveSectionFromCourse = sectionIdToRemove => {
    setSections(getArrayWithoutEntry(sections, sectionIdToRemove))
  }


  const handleRemoveTopicFromLesson = (topic, lesson) => {
    const updatedLesson = {
      ...lesson,
      children: getArrayWithoutEntry(lesson.children, topic.id)
    }

    setLastEditedLessonId(lesson.id)
    setSections(getUpdatedArray(sections, updatedLesson))
  }


  const handleChangeTopicTitleInLesson = (lesson, topic, newTitle) => {
    const updatedTopic = {
      ...topic,
      title: newTitle
    }

    const updatedLesson = {
      ...lesson,
      children: getUpdatedArray(lesson.children, updatedTopic)
    }

    setLastEditedLessonId(lesson.id)
    setSections(getUpdatedArray(sections, updatedLesson))
  }


  const getCoursePartProps = section => {
    const commonActions = (
      <big>
        <IconButton
          icon={icons.removeCircle}
          variant="danger"
          onClick={() => handleRemoveSectionFromCourse(section.id)}
        />
      </big>
    )

    switch (section.type) {
      case 'section-heading':
        return {}

      case 'new-lesson':
        return {
          type: 'lesson',
          title: (<InlineEdit
            value={section.title}
            onChange={newTitle => setSectionTitle(section, newTitle)}
            placeholder="Enter lesson title"
            autoFocus
          />),
          actions: commonActions,
          isNew: true,
          className: 'highlight-actions-on-hover'
        }

      default:
        return {
          type: 'lesson',
          title: section.title,
          actions: (<>
            <Link to={`/lessons/${section.id}`} className="mr-1">
              <big>
                <IconButton icon={icons.edit} className="link-dark faded" />
              </big>
            </Link>
            {commonActions}
          </>)
        }
    }
  }


  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="main">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {sections.map((section, index) => (
              <Draggable key={section.id} draggableId={String(section.id)} index={index}>
                {draggableProvided => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    className={getSectionClassName(section, lastEditedLessonId)}
                  >
                    <>
                      <CoursePart
                        {...getCoursePartProps(section)}
                        dragHandleProps={draggableProvided.dragHandleProps}
                      />
                      {section.children &&
                        <DraggableTopics
                          lesson={section}
                          onChangeTopicTitleInLesson={handleChangeTopicTitleInLesson}
                          onRemoveTopicFromLesson={handleRemoveTopicFromLesson}
                        />
                      }
                    </>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}


CourseSchedule.propTypes = {
  sections: PropTypes.array.isRequired,
  setSections: PropTypes.func.isRequired,
  lastEditedLessonId: PropTypes.number,
  setLastEditedLessonId: PropTypes.func.isRequired
}
